html {
  background-color: #000000;
}

body {
  font-family: neue-kabel,sans-serif;
  font-weight: 400;
  font-style: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
