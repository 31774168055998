.App {
  background-color: #000000;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #000000;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

:root {
  --bg-dark: #000000;
  /* --bg-light: #DAD0ED; */
  --text-dark: #000000;
  --text-light: #ffffff;
}

.logo {
  /* position: absolute; */
  left: 50%;
  margin-left: -(128)px
}

.home-header {
  color: var(--text-white);
  font-size: xx-large;
  font-family: neue-kabel, sans-serif;
  font-weight: 800;
  font-style: normal;
  text-align: center;
}