:root {
    --text-blue: #1db3cb;
    --text-red: #e05a4e;
    --text-yellow: #ffb541;
    --text-white: #ffffff;
}
.page {
    background-color: #000000;
}
/* Page styles not mobile or tablet */
@media screen and (min-width: 601px) {
    .about-header {
        color: var(--text-blue);
        font-size: xxx-large;
        font-family: neue-kabel, sans-serif;
        font-weight: 800;
        font-style: normal;
    }

    .videos-header {
        color: var(--text-red);
        font-size: xxx-large;
        font-family: neue-kabel, sans-serif;
        font-weight: 800;
        font-style: normal;
    }

    .newsletter-header {
        color: var(--text-yellow);
        font-size: xxx-large;
        font-family: neue-kabel, sans-serif;
        font-weight: 800;
        font-style: normal;
    }

    .contact-header {
        color: var(--text-blue);
        font-size: xxx-large;
        font-family: neue-kabel, sans-serif;
        font-weight: 800;
        font-style: normal;
    }

    .page-text { 
        color: var(--text-white);
        font-family: acumin-pro-condensed, sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 2vw;
    }
}
  
/* If the screen size is 600px wide or less, set the font-size of <div> to 30px */
@media screen and (max-width: 600px) {
    .about-header {
        color: var(--text-blue);
        font-size: xx-large;
        font-family: neue-kabel, sans-serif;
        font-weight: 800;
        font-style: normal;
        margin-left: 5%;
    }

    .videos-header {
        color: var(--text-red);
        font-size: xx-large;;
        font-family: neue-kabel, sans-serif;
        font-weight: 800;
        font-style: normal;
        margin-left: 5%;
    }

    .contact-header {
        color: var(--text-blue);
        font-size: xx-large;
        font-family: neue-kabel, sans-serif;
        font-weight: 800;
        font-style: normal;
        margin-left: 5%;
    }

    .newsletter-header {
        color: var(--text-yellow);
        font-size: xx-large;
        font-family: neue-kabel, sans-serif;
        font-weight: 800;
        font-style: normal;
        margin-left: 5%;
    }    

    .page-text { 
        color: var(--text-white);
        font-family: acumin-pro-condensed, sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 5vw;
        margin-left: 5%;
    }
}