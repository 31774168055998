:root {
  --text-blue: #1db3cb;
  --text-red: #e05a4e;
  --text-yellow: #ffb541;
  --text-white: #ffffff;
}

.header-style {
    background-color: var(--bg-dark);
    /* color: var(--text-light); */
    font-family: neue-kabel, sans-serif;
    font-weight: 800;
    font-style: normal;
    padding-bottom: 0%;
}

.navbar-toggler {
  border-color: white;
}

.navbar-toggler-icon{
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.nav-link-about, .nav-link-videos, .nav-link-newsletter, .nav-link-contact {
  color: white;
  font-size: xx-large;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  text-decoration: none;
}

.nav-link-about:hover {
  color: var(--text-blue);
}

.nav-link-videos:hover {
  color: var(--text-red);
}

.nav-link-newsletter:hover {
  color: var(--text-yellow);
}

.nav-link-contact:hover {
  color: var(--text-blue);
}

hr.nav-bottom-border {
  border: 2px solid white;
  border-radius: 2px;
  width:75%;
  margin:0 auto;
}